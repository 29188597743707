$sz--arrow: 16px;
$sz--arrow-diag: ceil(($sz--arrow / 1px) * 2 / sqrt(2)) * 1px;
$sz--border: $sz--border-width;
$sz--popup-max: 240px;

$c--border: $c--monochrome-steel-grey;
$c--background: $c--monochrome-white;


.mapboxgl-canvas {
    bottom: 0;
    left: 0;
}

.mapboxgl-popup {
    pointer-events: none;
    max-width: $sz--popup-max;

    &.mapboxgl-popup-anchor-top {
        margin-bottom: $sz--arrow / -2;

        .mapboxgl-popup-tip {
            left: 50%;
            bottom: 100%;
            transform: translate(-50%, 0) rotateZ(0.5turn);
            margin-bottom: -$sz--border;
        }
    }

    &.mapboxgl-popup-anchor-bottom {
        margin-top: $sz--arrow / -2;

        .mapboxgl-popup-tip {
            left: 50%;
            top: 100%;
            transform: translate(-50%, 0) rotateZ(0);
            margin-top: -$sz--border;
        }
    }
}

.mapboxgl-popup-content {
    @include ff--deco-bold;
    pointer-events: none;
    display: flex;
    padding: 10px 18px 5px;
    border: $sz--border-width solid $c--border;
    border-radius: $sz--border-radius;
    background-color: $c--monochrome-white;
    font-size: $fz--small;
    line-height: 1.12;
}

.mapboxgl-popup-tip {
    & {
        z-index: 1;
        position: absolute;
        width: $sz--arrow-diag;
        height: $sz--arrow-diag;
        border: none;
        overflow: hidden;
    }

    &::before, &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        display: block;
        width: $sz--arrow;
        height: $sz--arrow;
        background-color: currentColor;
    }

    &::before {
        transform: translate(-50%, -50%) rotateZ(45deg);
    }

    &::after {
        transform: translate(-50%, -50%) rotateZ(45deg) translate(-$sz--border, -$sz--border);
        background-color: $c--background;
        margin-bottom: -$sz--border;
    }
}
