.link-list {
    // Vars

    // Support

    // Module
    & {
        .link-list--title {
            margin-bottom: 8px;
        }

        .link-list--item {
            ~ .link-list--item {
                margin-top: 4px;
            }
        }

        .link-list--link {
            @include has-focus(default) {
                color: $c--brand-light-blue;
                transition: color $trs--base;
            }

            @include has-focus(true) {
                color: color-shade($c--brand-light-blue, 20%);
            }
        }

        .link-list--link-text,
        .link-list--link-icon {
            display: inline;
        }

        .link-list--link-icon {
            position: relative;
            margin-right: (16px * 0.5);
            transform-origin: center left;
            transition: transform $trs--base;
        }
    }

    // Facets

    // States
    & {
        // Link hover
        .link-list--link {
            @include has-focus(true) {
                .link-list--link-icon {
                    transform: scale(1.5, 1.5);
                }
            }
        }
    }
}
