.home-intro-toggle {
    // Vars

    // Support

    // Module
    & {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        .home-intro-toggle--wrap {
            @include mq(('screen' '>lg')) {
                display: none;
            }
        }

        .home-intro-toggle--btn {
            padding: 18px;
        }

        .home-intro-toggle--icon {
            width: 20px;
            height: 20px;
        }
    }

    // Facets

    // States
}
