.grid {
    // Vars

    // Support

    // Module
    & {
        /// Columns will be scaffolded in the corresponding modules
        /// @example scss - Basic usage
        ///     .my-component--col {
        ///         @include grid--scaffold((ratio: 1));
        ///     }
        ///
        @include grid--root();

        .grid--container {
            &.grid--container__fluid {
                @include grid--container((container-size: none));
            }
        }
    }

    // Facets
    @import 'grid__base';
    @import 'grid__split';
    @import 'grid__placeholder';
    @import 'grid__gallery';

    // States
}
