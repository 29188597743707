&.form-field__floating-label {
    // Vars
    $label--offset: 14px;

    // Modules
    & {
        transform: rotateZ(1turn); // NOTE: Prevent jitter when animating floating-label

        .form-field--input,
        .form-field--floating-label {
            padding-top: $sz--padding-top + $label--offset;
            padding-bottom: $sz--padding-bottom - $label--offset;
        }

        .form-field--floating-label {
            pointer-events: none;
            position: absolute;
            font-size: $fz--medium;
            line-height: $lh--medium;
            top: 0;
            left: $sz--padding-x + $sz--input-border-width;
            visibility: visible;
            backface-visibility: hidden;
            transform-origin: 0 50%;
            transition: transform $trs--base, color $trs--base;

            // NOTE: Text-ellipsis
            white-space: nowrap;
            overflow: hidden;
            max-width: 100%;
            text-overflow: ellipsis;
        }

        .form-field--title-label {
            @include hide-visually;
        }
    }

    // Variants

    // States
    &.form-field__is-floating {
        .form-field--floating-label {
            transform: translate(0, -30%) scale(0.6);
        }
    }
}
