// basic size reset
html, body {
    width: 100%;
    height: 100%;
}


html {
    overflow: visible;
}


body {
    // native scroll
    @include ios-native-scrolling;

    // selection
    @include is-selectable(true);

    cursor: auto;
    background-color: transparent;
}


.templates, .template,
.controllers, .controller {
    display: none;
}

.u-icon-source, .u-icon-sprite, .u-svg-filter-source {
    @include hide-visually;
}

