////
/// Button facets and state generation library
////

/// Dependencies and components
///
@import 'btn__vars';
@import 'btn__helpers';
@import 'btn__extends';


/// Apply button styles to their respective selectors
/// @param {string} $type - A button-facet name defined in `$button-facets`
///
@mixin btn--style($type) {
    &:not(:disabled):not([data-disabled]) {
        // normal (and not disabled)
        & {
            @include has-focus(false) {
                @include _btn--apply-style($type, 'base');
                text-decoration: none;
            }
        }

        // focus (and not inert)
        &:not([data-inert]) {
            @include has-focus(true) {
                @include _btn--apply-style($type, 'focus');
                text-decoration: none;
            }
        }
    }

    // disabled
    &:disabled,
    &[data-disabled] {
        @include _btn--apply-style($type, 'disabled');
        text-decoration: none;
        cursor: inherit;
    }

    // inert
    &[data-inert] {
        cursor: default;
    }
}

/// Apply basic button styles to all interactive button-like elements
///
.skel .skel--main {
    input[type='button'],
    input[type='submit'],
    input[type='reset'],
    input[type='file'],
    button,
    a {
        &:not(.u-reset) {
            &:not([disabled]):not([data-disabled]) {
                @extend %btn;
            }
        }
    }
}

/// TODO: Sassdoc
///
.btn {
    // Vars

    // Support

    // Module
    & {
        @extend %btn;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border: $sz--border-width solid transparent;
        border-radius: $sz--border-radius;
        overflow: hidden;

        @include grid--root((
            row: 'wrap',
            cols: ('icon', 'text'),
        ));
        @include grid--gutter((
            gutter: 8px,
            row: 'wrap',
            cols: ('icon', 'text'),
        ));

        .btn--wrap {
            display: inline-flex;
            align-items: center;
            justify-content: center;
        }

        .btn--text {
            // NOTE: Depending on the font, we need a correction value to visually center our text:
            position: relative;
            transform: translateY(1px);
        }

        .btn--ink-ripple {
            display: none;
        }
    }

    // Facets
    & {

        // Bubble
        &.btn__bubble {
            min-width: 0;
            border-radius: 50%;

            &::before {
                border-radius: 50%;
            }
        }

        &, &.btn__medium {
            padding: 13px 24px 11px;
            font-size: $fz--medium;

            &.btn__bubble {
                padding: 10px;
            }
        }

        &.btn__large {
            &.btn__bubble {
                padding: 14px;
            }
        }

        // Facets
        &.btn__primary {
            @include btn--primary;
        }

        &.btn__secondary {
            @include btn--secondary;
        }

        &.btn__light {
            @include btn--light;
        }

        &.btn__overlay {
            @include btn--overlay;
        }

        &.btn__has-ink-ripple {
            .btn--ink-ripple {
                display: block;
            }
        }
    }

    // States
    & {
        @include _btn--define-facets();
    }
}
