.map {
    // Vars

    // Support

    // Module
    & {
        z-index: z-index(map);
        position: fixed;
        left: 0;
        width: 100vw;
        height: 100vh;

        .map--positionner {
            position: relative;
            height: 100%;

            @include mq(('screen' '>lg')) {
                width: 50%;
            }
        }

        .map--content {
            opacity: 0;
            transition: opacity $trs--base;

            &.map--content__is-allowed {
                opacity: 1;
            }
        }

        .map--logo {
            z-index: 10;
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            height: 32px;
            margin: 24px 0 0 24px;

            @include mq(('print', '<=lg')) {
                display: none;
            }
        }

        .map--logo-image {
            display: block;
            height: 100%;
        }
    }

    // Facets
    & {
        & {
            top: 0;
            bottom: auto;
        }

        &.map__attach-to-bottom {
            top: auto;
            bottom: 0;
        }
    }

    // States
}
