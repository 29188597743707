.home-placeholder {
    // Vars

    // Support

    // Module
    & {
        .home-placeholder--wrap {
            width: 100%;

            @include mq(('screen' '>lg')) {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                height: 100%;
            }
        }

        .home-placeholder--concentrator {
            width: 100%;
        }

        .home-placeholder--col {
            @include grid--scaffold((ratio: 1));
        }

        .home-placeholder--logo {
            @include mq(('screen' '>lg')) {
                width: 342px;
                padding-bottom: 40px;
            }

            @include mq(('print', '<=lg')) {
                width: 226px;
                max-width: 100%;
                padding-bottom: 32px;
            }
        }
    }

    // Facets

    // States
}
