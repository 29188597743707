.form-submit {
    // Vars

    // Support

    // Module
    & {
        .form-submit--wrap {
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-end;
            align-items: flex-end;

            > div {
                margin-bottom: 24px;
            }

            .grecaptcha-badge {
                position: relative;
                margin-right: 2px;
            }
        }

        .form-submit--general-errors {
            margin-top: 8px;
        }

        .form-submit--general-error {
            display: block;
            width: 100%;

            ~ .form-submit--general-error {
                margin-top: 4px;
            }
        }

        .form-submit--btn {
            position: relative;
        }

        .form-submit--btn-content {
            transition: opacity $trs--base;
        }

        .form-submit--wave-loader {
            @include overlay();
            z-index: 1;
        }
    }

    // Facets

    // States
    & {
        .form-submit--btn {
            &.form-submit--btn__is-submitting {
                .form-submit--btn-content {
                    opacity: 0;
                }
            }
        }
    }
}
