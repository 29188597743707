.media-portal-item {
    // Vars

    // Support

    // Module
    & {
        width: 100%;
        height: 100%;
        padding: $sz--media-portal-inset;
        overflow: hidden;

        @include mq(('print', '<=sm')) {
            padding: $sz--media-portal-inset-small;
        }

        .media-portal-item--wrap {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            align-items: stretch;
            width: 100%;
            height: 100%;
        }

        .media-portal-item--description {
            @include type--aside;
            margin-top: 28px;
            text-align: center;

            @include mq(('print', '<=sm')) {
                margin-top: 20px;
            }
        }
    }

    // Facets
    & {
        &.media-portal-item__image {
            .media-portal-item--img {
                position: relative;
                flex: 0 1 100%;
                background-size: contain;
                background-repeat: no-repeat;
                background-position: center;
            }

            .media-portal-item--img-el {
                @include overlay();
                opacity: 0;
            }
        }

        &.media-portal-item__audio,
        &.media-portal-item__video {
            .media-portal-item--medium {
                position: relative;
                width: 100%;
                padding-top: 100% / 16 * 9;
            }

            .media-portal-item--iframe {
                @include overlay();
            }
        }
    }

    // States
}
