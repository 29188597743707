.main-nav {
    // Vars
    $c--link-base: $c--label-link;
    $c--link-hover: color-shade($c--label-link, 20%);

    // Support
    @mixin main-nav--wave-link() {
        position: relative;
        padding-bottom: 10px;

        &::after {
            @include overlay();
            content: '';
            pointer-events: none;

            display: block;

            background-image: url('#{wave--svg($c--link-base)}');
            background-repeat: repeat-x;
            background-position: bottom center;
            opacity: 0;

            transition: opacity $trs--base;
        }

        &.main-nav--link__is-active-exact {
            &::after {
                opacity: 1;
            }
        }
    }

    // Module
    & {
        .main-nav--items {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
        }

        .main-nav--link {
            display: block;
            overflow: hidden;

            font-size: $fz--large;
            line-height: $lh--large;
            white-space: nowrap;

            transition: color $trs--base;

            @include has-focus(true) {
                color: $c--link-base;
            }

            &.main-nav--link__is-active-exact {
                color: $c--link-base;
            }
        }
    }

    // Facets
    & {
        &.main-nav__base {
            .main-nav--item {
                ~ .main-nav--item {
                    margin-left: 24px;
                }
            }

            .main-nav--wave {
                pointer-events: none;
                z-index: 1;
                position: absolute;
                bottom: 0;
                right: 0;
                width: 100%;
                height: 100%;

                background-repeat: repeat-x;
                background-position-y: bottom;
            }

            .main-nav--wave {
                width: 0;
                background-image: url('#{wave--svg($c--link-base)}');
                transition: transition--props($trs--base, width, transform, background-position-x);

                @include mq(('print', '<=lg')) {
                    display: none;
                }
            }
        }

        &.main-nav__split {
            .main-nav--link {
                @include main-nav--wave-link();
            }
        }

        &.main-nav__mobile {
            @include overlay(fixed);
            padding-top: $sz--header-height-base - $sz--wave-height;

            &::after {
                content: '';
                position: absolute;
                top: $sz--header-height-base - $sz--wave-height;
                left: 0;
                display: block;
                width: 100%;
                height: $sz--wave-height;

                background-color: $c--monochrome-white;
            }

            .main-nav--list {
                @include ios-native-scrolling;
                @include wave--cap();
                z-index: 1;
                position: relative;
                max-height: 100%;
                padding-top: $sz--wave-height;
            }

            .main-nav--mobile-backdrop {
                @include overlay();
                z-index: 0;
                cursor: default;
            }

            .main-nav--items {
                flex-direction: column;
                justify-content: center;
                align-items: center;

                padding: 40px 0 50px;
            }

            .main-nav--item {
                ~ .main-nav--item {
                    margin-top: 30px;
                }
            }

            .main-nav--link {
                @include main-nav--wave-link();
            }
        }
    }

    // States
}
