// stylelint-disable property-no-vendor-prefix, no-duplicate-selectors, selector-max-universal, selector-no-qualifying-type

////
/// Helper classes for common tasks, that shall be used throughout the document and are directly included into the CSS
////

/// Element Streamlining (additional resets)
///
html {
    box-sizing: border-box;
}

*, *::before, *::after {
    box-sizing: inherit;
    text-decoration: none;
}

/// Type legibility improvements where applicable, use with care
///
body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}

/// Small fix for many z-index related problems on Android 2.3 Stock Browser
///
body {
    z-index: 0;
}

/// IE10 mobile and hybrid fix
///
* {
    -ms-touch-action: pan-y;
    -ms-content-zooming: none;
}

/// Removes the 300ms touch delay on windows phones and Chrome/Firefox
///
html {
    -ms-touch-action: manipulation;
    touch-action: manipulation;
}


/// IE10, 11 and Edge: autohiding scrollbars
///
body, * {
    -ms-overflow-style: -ms-autohiding-scrollbar;
}


/// Removes the default context menu on longpress, use carefully in other projects
///
// body { -webkit-touch-callout: none; }
// a { -webkit-user-select: none; }
