.mapbox-map {
    // Vars

    // Support

    // Module
    & {
        @include overlay();

        .mapbox-map--window-helper {
            @include overlay();
            pointer-events: none;
        }

        .mapbox-map--canvas-root {
            @include overlay();
        }

        .mapbox-map--actions-root {
            @include overlay();
            pointer-events: none;
            display: flex;
            flex-flow: column nowrap;
            justify-content: flex-end;
            align-items: flex-start;
            padding: 36px 54px;

            @include mq(('print', '<=lg')) {
                padding: 24px;
            }
        }

        .mapbox-map--action {
            pointer-events: auto;

            &.mapbox-map--action__zoom-in {
                margin-top: 24px;
            }

            &.mapbox-map--action__zoom-out {
                margin-top: 12px;
            }
        }

        .mapbox-map--button {
            @extend %btn;
        }
    }

    // Facets
    & {
        @include mq(('print', '<=lg')) {
            &.mapbox-map__page-location {
                .mapbox-map--window-helper {
                    @each $key, $value in $split-spacing {
                        @include mq($key) {
                            height: nth($value, 2);
                        }
                    }
                }
            }

            &.mapbox-map__page-home.mapbox-map__page-home-base {
                .mapbox-map--window-helper {
                    $sz--offset-top: $sz--header-height-base + $sz--map-nav-dropdown-height;
                    top: auto;
                    bottom: 0;
                    height: calc(100vh - #{ $sz--offset-top });
                }
            }

            &.mapbox-map__page-home.mapbox-map__page-home-intro {
                @each $key, $value in $split-spacing {
                    @include mq($key) {
                        .mapbox-map--window-helper {
                            top: auto;
                            bottom: 0;
                            height: nth($value, 2);
                        }
                    }
                }
            }
        }
    }

    // States
}
