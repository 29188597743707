/// Estimated scrollbar size
///
$sz--scrollbar-offset: 16px;

/// Container sizes
/// In Chrome and some other browsers, the scrollbar doesn't affect the breakpoint.
/// So we decrease the container's size by about 15px for bigger screens.
///
$sz--container-base-big: (720px + $sz--scrollbar-offset);
$sz--container-base-small: (560px + $sz--scrollbar-offset);


/// Grid spacings
///

/// Grid: base
$grid--base-container-sizes: (
    '<=sm': none,
    'md': ($sz--container-base-small - $sz--scrollbar-offset),
    '>md': ($sz--container-base-big - $sz--scrollbar-offset),
);
$grid--base-spacing: (
    '<=sm': (gutter: 16px, edge: 16px),
    '>sm': (gutter: 16px, edge: 0),
);

/// Grid: split
$grid--split-container-sizes: (
    '<=sm': none,
    '>sm': ($sz--container-base-small - $sz--scrollbar-offset),
);
$grid--split-spacing: (
    'xs': (gutter: 16px, edge: 16px),
    'sm': (gutter: 20px, edge: 20px),
    '>sm': (gutter: 16px, edge: 0),
);

/// Grid: placeholder
$sz--placeholder-spacing-vertical-base: 52px;
$sz--placeholder-spacing-vertical-small: 36px;
$grid--placeholder-spacing: (
    'xs': (gutter: ($sz--placeholder-spacing-vertical-small null), edge: (0 null)),
    'sm': (gutter: ($sz--placeholder-spacing-vertical-base null), edge: (0 null)),
    '>sm': (gutter: ($sz--placeholder-spacing-vertical-base null), edge: (0 null)),
);

/// Grid: gallery
$grid--gallery-spacing: (
    'xs': (gutter: (16px 16px), edge: (0 0)),
    'sm': (gutter: (20px 20px), edge: (0 0)),
    '>sm': (gutter: (24px 24px), edge: (0 0)),
);
