.tinted-image {
    // Vars
    $c--tint-color: rgba(#0501a9, 0.1);

    // Support

    // Module
    & {
        .tinted-image--button {
            @include overlay();
            display: block;
            backface-visibility: hidden;
            overflow: hidden;

            &::after {
                @include overlay();
                content: '';
                pointer-events: none;
                display: block;
                background-color: $c--tint-color;

                transition: opacity $trs--base;
            }
        }

        .tinted-image--thumb {
            @include overlay();
            display: block;
        }

        .tinted-image--img {
            @include overlay();

            &.tinted-image--img__color {
                transition: opacity $trs--base;
            }
        }
    }

    // Facets
    & {
        &.tinted-image--img.tinted-image--img__fallback {
            display: none;
        }

        &.tinted-image__location-intro {
            position: relative;
            width: 100%;
            padding-top: 100% * 9 / 16;
        }

        &.tinted-image__gallery-image {
            position: relative;

            @include mq(('screen' '>sm')) {
                width: 100%;
                padding-top: 100% * 9 / 16;
            }

            @include mq(('print', '<=sm')) {
                width: 100%;
                height: 100%;
            }
        }

        &.tinted-image__inert {
            .tinted-image--button {
                cursor: default;
            }
        }
    }

    // States
    & {
        .tinted-image--button {
            & {
                @include has-focus(false) {
                    &::after {
                        opacity: 1;
                    }

                    .tinted-image--img.tinted-image--img__color {
                        opacity: 0;
                    }
                }
            }

            &:not(:disabled):not([data-disabled]) {
                @include has-focus(true) {
                    &::after {
                        opacity: 0;
                    }

                    .tinted-image--img.tinted-image--img__color {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
