&.grid__split {
    // Vars

    // Support

    // Module
    & {
        @each $key, $value in $grid--split-container-sizes {
            @include mq($key) {
                .grid--container {
                    @include grid--container((container-size: $value));
                }
            }
        }

        @each $key, $value in $grid--split-spacing {
            @include mq($key) {
                @include grid--gutter($value);
            }
        }
    }

    // Facets

    // States
}
