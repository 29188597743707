.gallery {
    // Vars

    // Support

    // Module
    & {
        z-index: 2;

        + .gallery {
            z-index: 1;

            @each $key, $value in $grid--placeholder-spacing {
                @include mq($key) {
                    $top-gutter: nth(map-get($value, gutter), 1);
                    margin-top: $top-gutter * -1;
                }
            }

            @each $key, $value in $grid--gallery-spacing {
                @include mq($key) {
                    $top-gutter: nth(map-get($value, gutter), 1);

                    .gallery--wrap {
                        padding-top: $top-gutter;
                    }
                }
            }
        }

        .gallery--item {
            @include grid--scaffold((ratio: 1/3, mq: '>sm'));
            overflow: hidden;
        }
    }

    // Facets
    & {
        &.gallery__media {
            .gallery--item {
                &:first-child {
                    @include grid--scaffold((ratio: 2/3, mq: '>sm'));
                }
            }
        }
    }

    // States
    & {
        // Print
        @include mq('print') {
            .gallery--item {
                flex: 0 0 auto;
            }
        }

        // Mobile
        @include mq(('screen' '<=sm')) {
            .gallery--break-out {
                position: relative;
                left: 50%;
                width: 100vw;
                overflow: hidden;
            }

            .gallery--container {
                @include ios-native-scrolling(x);
                font-size: 0;
            }

            .gallery--items {
                display: inline-flex;
                flex-wrap: nowrap;
            }

            .gallery--item {
                font-size: $fz--medium;
            }

            @each $key, $value in $grid--base-spacing {
                @include mq($key) {
                    $gutter: map-get($value, gutter);
                    $edge: map-get($value, edge);

                    .gallery--item ~ .gallery--item {
                        margin-left: $gutter;
                    }

                    //.gallery--rail {}

                    .gallery--items {
                        padding-left: $edge;
                        padding-right: $edge;
                    }
                }
            }
        }
    }
}
