&.form-field__input {
    // Vars
    $max-actions: 3;
    $sz--padding-x: 16px;
    $sz--padding-top: 12px;
    $sz--padding-bottom: 8px + 14px;
    $sz--icon-width: 28px;
    $sz--icon-margin: 8px;
    $sz--input-border-width: 1px;

    // Support

    //@mixin form-field--toggle-signal($toggle, $signal-type, $action-name) {
    //    .form-field--#{$signal-type} {
    //        &.form-field--#{$signal-type}__#{$action-name} {
    //            @include is-visible($toggle, $trs--base-speed, color, opacity);
    //            opacity: if($toggle, 1, 0);
    //        }
    //    }
    //}

    // Module
    & {
        //@include form-field--toggle-signal(false, 'action', 'clear');
        //@include form-field--toggle-signal(true, 'action', 'info');

        .form-field--input-container {
            position: relative;

            // Padding right for the actions, depending on the action count
            // Limit action count to 3, that should be enough for every case...
            @for $i from 1 through $max-actions {
                &[data-action-count='#{$i}'] {
                    .form-field--input {
                        padding-right: ($sz--icon-margin * 2) + ($i * $sz--icon-width);
                    }
                }
            }
        }

        .form-field--title-label {
            cursor: pointer;
            transition: $trs--common-props;
        }

        //.form-field--message-container {}

        .form-field--input {
            display: inline-block;
            width: 100%;
            padding: $sz--padding-top $sz--padding-x $sz--padding-bottom;
            border-width: $sz--input-border-width;
            border-style: solid;
            border-radius: $sz--border-radius;
            line-height: 1.3;
            cursor: text;

            transition: $trs--common-props;

            @include placeholder() {
                transition: $trs--common-props;
            }
        }

        .form-field--actions {
            pointer-events: none;

            position: absolute;
            top: 0;
            left: 0;

            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0 $sz--icon-margin 0 $sz--padding-x;
        }

        .form-field--action {
            pointer-events: auto;

            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
        }

        .form-field--action {
            cursor: pointer;

            width: $sz--icon-width;
            height: auto;
            padding: 2px;

            transition: color $trs--base;
        }
    }

    // Facets
    & {
        // Base facet
        @import 'facets/form-field__input-facet-base';

        // Type facets
        @import 'form-field__hidden'; // NOTE: Use only with `input[type=hidden]`
        @import 'form-field__select'; // NOTE: Use only with `select`
        @import 'form-field__textarea'; // NOTE: Use only with `textarea`

        // Floating variants
        @import 'form-field__floating-label';
    }

    // States
    @import 'form-field__input-states-general';
}
