& {
    // Vars
    $c--text-title-label: $c--label-text;
    $c--text-placeholder: $c--monochrome-dark-grey;
    $color-facets: (
        base: (
            'color': $c--label-text,
            'background-color': transparent,
            'border-color': $c--monochrome-silver,
        ),
        focus: (
            'color': null,
            'background-color': null,
            'border-color': $c--monochrome-dark-grey,
        ),
        disabled: (
            'color': $c--monochrome-silver,
            'background-color': $c--monochrome-light-grey,
            'border-color':transparent,
        ),
    );

    // Support

    // Module
    & {
        .form-field--title-label {
            color: $c--text-title-label;
        }

        .form-field--input {
            @include form-field--apply-styles('base');

            @include placeholder() {
                color: $c--text-placeholder;
            }
        }

        .form-field--action {
            @include has-focus(default) {
                color: $c--text-title-label;
            }
        }
    }

    // Facets
    & {
        // Floating labels
        @import 'form-field__input-facet-base-floating-label'; // NOTE: Floating label styles
    }

    // States
    & {
        // General input

        // Is applied, when form-field has an error state.
        &.form-field__has-error {
            .form-field--input {
                border-color: $c--signal-error;
            }

            .form-field--title-label {
                color: $c--signal-error;
            }
        }

        // Is applied, when pseudo-class `:focus` is applied to `.form-field--input`.
        &.form-field__has-focus {
            .form-field--input {
                @include form-field--apply-styles('focus');
            }

            .form-field--title-label {
                color: $c--text-title-label;
            }
        }

        // Is applied, when form-field is disabled.
        &.form-field__is-disabled {
            .form-field--input {
                @include form-field--apply-styles('disabled');
            }

            .form-field--action {
                @include has-focus(default) {
                    color: form-field--apply-style('disabled', 'color');
                }
            }
        }

        // Select
        &.form-field__select {
            & {
                .form-field--input {
                    color: form-field--apply-style('base', 'color');
                }

                .form-field--action {
                    color: $c--brand-light-blue;
                }
            }

            &.form-field__is-empty {
                .form-field--input {
                    color: $c--text-placeholder;
                }
            }

            &.form-field__has-focus {
                .form-field--input {
                    border-color: form-field--apply-style('focus', 'border-color');
                }

                .form-field--action {
                    color: color-shade($c--brand-light-blue, 20%);
                }
            }

            &.form-field__is-disabled {
                .form-field--input {
                    color: form-field--apply-style('disabled', 'color');
                }

                .form-field--action {
                    color: transparent;
                }
            }
        }
    }
}
