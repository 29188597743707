.form-field {
    // Vars
    $color-facets: ();

    // Support
    @function form-field--apply-style($prop: 'base', $type: null) {
        $style-value: map-deep-get($color-facets, $prop, $type);

        @if not $style-value {
            $style-value: map-deep-get($color-facets, 'base', $type);
        }

        @return $style-value;
    }

    @mixin form-field--apply-styles($prop: 'base') {
        $styles: map-deep-get($color-facets, $prop);

        @each $style-key, $style-value in $styles {
            @if not $style-value {
                $style-value: map-deep-get($color-facets, 'base', $style-key);
            }

            #{$style-key}: form-field--apply-style($prop, $style-key);
        }
    }

    /// Appends a hair space and a star sign to discriminate required form fields
    ///
    @mixin form-field--is-required() {
        &::after {
            // NOTE: `\200A` defines a hair space.
            // @link https://en.wikipedia.org/wiki/Whitespace_character#Spaces_in_Unicode

            // NOTE: This is not wanted in this project
            //content: '\200A*';
        }
    }

    // Module
    & {
        @include is-selectable(true);
        display: flex;
        flex-flow: column nowrap;
        font-size: $fz--small;
        line-height: $lh--small;

        .form-field--input-container {
            position: relative;
            display: flex;
        }

        .form-field--message-container {
            position: relative;
            margin-top: 2px;
            font-size: $fz--xx-small;
            line-height: $lh--xx-small;
        }

        .form-field--title-label {
            @include ff--base-medium;
            margin-bottom: 4px;
            font-size: $fz--xx-small;
            line-height: $lh--xx-small;
        }

        .form-field--message {
            display: block;
            font-size: $fz--xx-small;
            line-height: $lh--xx-small;

            &:first-child {
                margin-top: 4px;
            }

            ~ .form-field--message {
                margin-top: 3px;
            }

            &.form-field--message__info {
                color: $c--signal-info;
            }

            &.form-field--message__error {
                color: $c--signal-error;
            }
        }
    }

    // Facets
    @import 'input/form-field__input';
    @import 'bool/form-field__bool';

    // States
}
