/* stylelint-disable unit-blacklist */

$sz--page-width: 210mm;
$sz--page-height: 297mm;
$sz--page-margin: 16mm;


@page {
    size: A4; // stylelint-disable-line value-keyword-case
    margin: $sz--page-margin;
}

@media print {
    html, body {
        display: block;
        width: $sz--page-width;
        height: $sz--page-height;

        font-size: 9pt;
        line-height: 1.2;
    }
}
