.trs-page-location {
    // Vars

    // Support

    // Module

    // Facets

    // States
    &-enter-active, &-leave-active {
        transition: transition--props($trs--page-location, opacity, transform);
    }

    &-enter, &-leave-to {
        opacity: 0;
        transform: translateY(400px);
    }
}
