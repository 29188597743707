@function wave--svg($color) {
    $svg-data: '<path fill="#{$color}" d="M3.669.842C2.33.224 1.521 0 0 0v2c1.187 0 1.732.15 2.83.658 1.34.618 2.15.842 3.67.842s2.33-.224 3.669-.842C11.268 2.151 11.813 2 13 2V0c-1.52 0-2.33.224-3.67.842-1.098.507-1.643.658-2.83.658S4.768 1.35 3.669.842z"/>';
    @return svg--create(13, ($sz--wave-height / 1px), $svg-data);
}


@mixin wave--cap() {
    background-repeat: repeat-x;
    background-position: right bottom;
    background-color: transparent;
    background-image:
        url('#{wave--svg($c--monochrome-light-grey)}'),
        linear-gradient(to top, rgba($c--monochrome-white, 0) 2px, $c--monochrome-white 2.01px, $c--monochrome-white);
}
