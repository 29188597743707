.location-nav {
    // Vars
    $c--border-base: $c--monochrome-light-grey;
    $c--border-focus: $c--brand-light-blue;

    // Support
    @mixin location-nav--border-helper($color, $directions) {
        transition: transition--props($trs--base-speed $trs--base-fn, opacity, border-color);

        @each $direction in $directions {
            border-#{$direction}: $sz--border-width solid $color;
        }
    }

    @mixin location-nav--outline-helper() {
        @include location-nav--border-helper($c--border-base, (right));
        content: '';
        position: absolute;
        top: -$sz--border-width;
        width: 0;
        height: calc(#{100%} + #{2px});
    }

    // Module
    & {
        pointer-events: none;
        z-index: z-index(location-nav);
        position: fixed;
        bottom: 0;
        right: 0;

        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: stretch;
        width: 100%;
        overflow: hidden;

        .location-nav--wrap {
            pointer-events: auto;
            position: relative;
            width: 100%;
            overflow: hidden;

            @include mq(('screen' '>lg')) {
                width: 50%;
            }
        }

        .location-nav--bar {
            display: flex;
            flex-flow: row nowrap;
            align-items: stretch;
            justify-content: space-between;
            background-color: $c--monochrome-white;

            &::before {
                @include overlay();
                @include location-nav--border-helper($c--border-base, (top));
                content: '';
                z-index: 0;
                height: 0;
            }
        }

        .location-nav--prev {
            @include location-nav--border-helper($c--border-base, (top, right));
            z-index: 1;
            position: relative;
            flex: 0 0 $sz--location-nav-size;

            &::after {
                @include location-nav--outline-helper();
                z-index: 2;
                right: -$sz--border-width;
            }
        }

        .location-nav--next {
            @include location-nav--border-helper($c--border-base, (top));
            z-index: 1;
            position: relative;
            flex: 0 1 100%;

            @include grid--fix-ie-flex-basis() {
                width: 100%;
            }

            &::after {
                @include location-nav--outline-helper();
                z-index: 3;
                left: -$sz--border-width;
            }

            &:first-child {
                margin-left: $sz--location-nav-size;

                &::after {
                    @include location-nav--border-helper($c--border-base, (right));
                }
            }
        }

        .location-nav--ripple {
            transition: $trs--base opacity;
        }

        .location-nav--description {
            @include location-nav--border-helper($c--border-base, (right));
            flex: 0 1 100%;

            @include grid--fix-ie-flex-basis() {
                width: 100%;
            }
        }

        .location-nav--icon {
            transition: transition--props($trs--base, transform, opacity);
        }

        .location-nav--icon-wrap {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            flex: 0 0 $sz--location-nav-size;
            min-height: $sz--location-nav-size;
        }

        .location-nav--btn-wrap {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            color: $c--brand-light-blue;
        }

        .location-nav--description {
            display: flex;
            flex-flow: column nowrap;
            justify-content: center;
            text-align: left;
            padding: 7px 26px;

            @include mq(('print', '<=sm')) {
                padding: 4px 8px;
            }
        }

        .location-nav--title {
            @include ff--deco-medium;
            font-size: $fz--small;
            margin-top: 6px;
        }

        .location-nav--loc-name {
            position: relative;
            color: $c--label-text;
        }
    }

    // Facets

    // States
    & {
        .location-nav--next,
        .location-nav--prev {
            cursor: pointer;

            &[disabled] {
                cursor: default;

                .location-nav--icon {
                    color: $c--monochrome-light-grey;
                }

                .location-nav--ripple {
                    opacity: 0;
                }
            }

            &:not([disabled]) {
                @include has-focus(true) {
                    border-top-color: $c--border-focus;

                    .location-nav--icon {
                        transform: scale(1.5, 1.5);
                    }
                }
            }
        }

        // stylelint-disable max-nesting-depth
        .location-nav--prev {
            &:not([disabled]) {
                @include has-focus(true) {
                    &::after {
                        border-right-color: $c--border-focus;
                    }

                    + .location-nav--next {
                        &::after {
                            border-right-color: $c--border-focus;
                        }
                    }
                }
            }
        }

        .location-nav--next {
            &:not([disabled]) {
                @include has-focus(true) {
                    &::after {
                        border-right-color: $c--border-focus;
                    }

                    .location-nav--description {
                        border-right-color: $c--border-focus;
                    }
                }
            }
        }
    }
}
