/// The z-index values of elements
///
$layers: (
    overlay: 5,
    location-nav: 3,
    loader: 2,
    content: 1,
    map: 0,
);

/// Returns a z-index stored within the `$layers` map, which stores z-indexes of all central ui elements
/// @param {string} $layer - Key that is searched for
/// @return {number} - A z-index number
///
@function z-index($layer: 'content') {
    @if not map-has-key($layers, $layer) {
        @error 'The layer `#{$layer}` is not contained within the $layers map!';
    }
    @return map-get($layers, $layer);
}
