.page-home {
    // Vars

    // Support

    // Module
    & {
        flex: 1 0 auto;

        @include grid--fix-ie-flex-basis() {
            height: auto;
        }

        @each $key, $value in $split-spacing {
            $sz--content-height: nth($value, 1);
            $sz--map-height: nth($value, 2);

            @include mq($key) {
                .page-home--content {
                    $sz--scrollable-height: calc(#{$sz--content-height} - #{$sz--map-height});

                    min-height: if($sz--content-height, $sz--scrollable-height, null);
                    margin-bottom: $sz--map-height;
                }

                .page-home--intro-toggle {
                    height: $sz--map-height;
                }
            }
        }

        .page-home--content {
            z-index: 2;
            position: relative;
            margin-top: 0;

            @include mq(('screen' '>lg')) {
                display: flex;
                flex-flow: column nowrap;
                justify-content: center;

                padding: $sz--header-height-split 0;
            }

            @include mq(('print', '<=lg')) {
                @include wave--cap();
                border-top: $sz--header-height-base solid $c--monochrome-white;
            }
        }

        .page-home--inner {
            @include mq(('screen' '>lg')) {
                display: flex;
                flex-flow: column nowrap;
                flex: 1 0 100%;
                justify-content: center;

                @include grid--fix-ie-flex-basis() {
                    height: 100%;
                }
            }
        }

        .page-home--intro-toggle {
            pointer-events: auto;
            z-index: 1;
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            overflow: hidden;
        }

        .page-home--intro-scroller {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        .page-home--map-nav {
            pointer-events: auto;
        }
    }

    // Facets

    // States
}
