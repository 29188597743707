.logo-list {
    // Vars

    // Support

    // Module
    & {
        .logo-list--link {
            display: block;
        }

        .logo-list--image {
            display: block;
        }
    }

    // Facets

    // States
}
