.trs-placeholder-content-fade {
    // Vars

    // Support

    // Module

    // Facets

    // States
    &-enter-active, &-leave-active {
        transition: transition--props($trs--placeholder-content, opacity);
    }

    &-enter-active {
        transition: transition--props($trs--placeholder-content $trs--placeholder-content-delay, opacity);
    }

    &-enter, &-leave-to {
        opacity: 0;
    }
}
