.page-content {
    // Vars

    // Support

    // Module
    & {
        position: relative;
        flex: 1 0 100%;

        .page-content--header {
            z-index: 2;
            position: fixed;
        }

        .page-content--content {
            z-index: 1;
            position: relative;
            padding: ($sz--header-height-base + 70px) 0 ($sz--location-nav-size + 50px);
        }
    }

    // Facets

    // States

    // Debug
}
