& {
    // Is applied, when form-field is disabled.
    &.form-field__is-disabled {
        .form-field--title-label {
            @include is-selectable(false);
            cursor: default;
        }

        .form-field--input {
            @include is-selectable(false);
            cursor: default;

            @include placeholder() {
                color: transparent;
            }
        }
    }

    // Is applied, when form-field is required
    &.form-field__is-required {
        .form-field--title-label {
            @include form-field--is-required();
        }
    }
}
