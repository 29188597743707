.general-error {
    // Vars

    // Support

    // Module
    & {
        display: inline-block;
        padding: 11px 12px 8px;
        color: $c--monochrome-white;
        background: $c--signal-error;
        font-size: $fz--xx-small;
        line-height: $lh--xx-small;
    }

    // Facets

    // States
}
