/// Font-styles, usable for project-wide styling of text instances
///
@mixin ff--base-medium($ff-definition: $ff--base-stack) {
    font-family: $ff-definition;
    font-weight: $fw--medium;
    font-style: normal;
}

@mixin ff--base-black($ff-definition: $ff--base-stack) {
    font-family: $ff-definition;
    font-weight: $fw--black;
    font-style: normal;
}

@mixin ff--deco-medium($ff-definition: $ff--deco-stack) {
    font-family: $ff-definition;
    font-weight: $fw--medium;
    font-style: normal;
}

@mixin ff--deco-bold($ff-definition: $ff--deco-stack) {
    font-family: $ff-definition;
    font-weight: $fw--bold;
    font-style: normal;
}
