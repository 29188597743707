.home-intro-scroller {
    // Vars
    $sz--ani-width: 18px;
    $sz--ani-height: 60px;

    // Support
    @keyframes scroll-intro {
        0% {
            opacity: 0;
        }

        20% {
            opacity: 1;
        }

        60% {
            opacity: 1;
        }

        75% {
            opacity: 0;
            transform: translateY(13px);
        }

        100% {
            opacity: 0;
            transform: translateY(13px);
        }
    }

    // Module
    & {
        @include mq(('print', '<=lg')) {
            display: none;
        }

        .home-intro-scroller--btn {
            margin-bottom: 24px;
        }

        .home-intro-scroller--wrap {
            position: relative;
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            cursor: pointer;
        }

        .home-intro-scroller--title {
            @include ff--deco-bold;
            font-size: $fz--small;
            line-height: $lh--small;
        }

        .home-intro-scroller--visual {
            position: relative;
        }

        .home-intro-scroller--img {
            display: block;
            margin: 0 auto;
        }

        .home-intro-scroller--ani {
            $sz--el-width: 2px;
            $sz--el-height: 5px;

            position: absolute;
            top: 5px;
            left: ($sz--ani-width - $sz--el-width) / 2;
            display: block;
            width: $sz--el-width;
            height: $sz--el-height;
            background-color: $c--brand-light-blue;
            border-radius: $sz--el-width;

            animation-name: scroll-intro;
            animation-duration: 2000ms;
            animation-iteration-count: infinite;
        }
    }

    // Facets

    // States
}
