/// Font weights used throughout the project
///
$fw--medium: 500;
$fw--bold: 700;
$fw--black: 900;

/// The project's font families
///
$ff--base: 'AvenirLTStd';
$ff--deco: 'BrandonTextWeb';

/// Font stack definitions
/// @link http://www.cssfontstack.com/
///
// stylelint-disable value-keyword-case
$ff--base-stack: $ff--base, 'Helvetica Neue', Helvetica, Arial, sans-serif;
$ff--deco-stack: $ff--deco, 'Arial Rounded MT Bold', 'Helvetica Rounded', Arial, sans-serif;
// stylelint-enable value-keyword-case
