.rich-text {
    // Vars

    // Support

    // Module

    .rich-text--content {
        /* stylelint-disable selector-max-universal */
        h2 {
            margin-bottom: 8px;
        }

        h3 {
            margin-bottom: 4px;
        }

        p, ul, ol {
            margin-bottom: 36px;
        }

        *:last-child {
            margin-bottom: 0;
        }

        /* stylelint-enable selector-max-universal */
    }

    // Facets

    // States
}

