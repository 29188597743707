.carousel-arrow-nav {
    // Vars

    // Support

    // Module
    & {
        @include overlay();
        pointer-events: none;

        @include mq(('print', '<=sm')) {
            display: none;
        }

        .carousel-arrow-nav--arrow {
            pointer-events: auto;
            position: absolute;
            top: 50%;
            margin: 20px;
            transform: translateY(-50%);

            &.carousel-arrow-nav--arrow__prev {
                left: 0;
            }

            &.carousel-arrow-nav--arrow__next {
                right: 0;
            }
        }
    }

    // Facets

    // States
}
