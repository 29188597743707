/// Web-static folder
///
$web-static: '../..';

/// Colors
/// see `util/colors.scss`

/// Layers (z-index)
/// see `util/z-index.scss`

/// Fonts
/// see `base/type.scss`

/// Sizes & spacing
/// see `base/spacing.scss`

/// Transitions & animations
///
$trs--project-basic-ease: cubic-bezier(0.25, 0.1, 0.25, 1.000);

$trs--base-speed: 200ms;
$trs--base-fn: ease(in-out-material);
$trs--base: $trs--base-speed $trs--base-fn;

$trs--overlay-speed: 400ms;
$trs--overlay-fn: ease(in-out-material);
$trs--overlay: $trs--overlay-speed $trs--overlay-fn;

$trs--btn-speed: 120ms;
$trs--btn-fn: ease;
$trs--btn: $trs--btn-speed $trs--btn-fn;

$trs--mobile-menu-speed: 300ms;
$trs--mobile-menu-fn: $trs--project-basic-ease;
$trs--mobile-menu: $trs--mobile-menu-speed $trs--mobile-menu-fn;

$trs--placeholder-content-speed: 400ms;
$trs--placeholder-content-fn: $trs--project-basic-ease;
$trs--placeholder-content-delay: 400ms;
$trs--placeholder-content: $trs--placeholder-content-speed $trs--placeholder-content-fn;

$trs--wave-loader-speed: 200ms;
$trs--wave-loader-fn: ease(in-out-material);
$trs--wave-loader-delay: 400ms;
$trs--wave-loader: $trs--wave-loader-speed $trs--wave-loader-fn;

$trs--page-location-speed: 300ms;
$trs--page-location-fn: $trs--project-basic-ease;
$trs--page-location: $trs--page-location-speed $trs--page-location-fn;

$trs--location-nav-speed: 300ms;
$trs--location-nav-fn: $trs--project-basic-ease;
$trs--location-nav-delay: $trs--page-location-speed;
$trs--location-nav: $trs--location-nav-speed $trs--location-nav-fn;

$trs--common-property-list: color, border-color, background-color, opacity, transform;
$trs--common-props: transition--props($trs--base, $trs--common-property-list...);
