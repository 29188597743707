.error {
    // Vars

    // Support

    // Module

    // Facets
    & {
        &.error__404 {
            z-index: 1;
            width: 100%;
            max-width: 768px;
            margin: auto;
            padding: 20px 24px;
            background-color: $c--monochrome-white;
            border: 1px solid $c--brand-light-blue;
        }
    }

    // States
}
