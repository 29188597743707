.trs-header-panel-switch {
    // Vars

    // Support

    // Module

    // Facets

    // States
    &-enter-active, &-leave-active {
        transition: transition--props($trs--mobile-menu, transform);
    }

    &-enter, &-leave-to {
        transform: translateY(-100%);
    }
}
