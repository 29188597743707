&.form-field__textarea {
    // Vars
    $sz--min-height: 50px;

    // Facets
    & {
        & {
            .form-field--input {
                max-width: 100%;
                min-height: $sz--min-height;
                resize: vertical;
            }
        }
    }
}
