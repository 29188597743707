.page-location {
    // Vars

    // Support

    // Module
    & {
        @each $key, $value in $split-spacing {
            @include mq($key) {
                .page-location--content {
                    margin-top: nth($value, 2);
                }
            }
        }

        .page-location--content {
            @include mq(('print', '<=lg')) {
                padding-bottom: ($sz--location-nav-size + $sz--placeholder-spacing-vertical-small);
            }
        }
    }

    // Facets

    // States
}
