.location-intro {
    // Vars

    // Support

    // Module
    & {
        .location-intro--header {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            margin-bottom: 20px;
        }

        .location-intro--title {
            position: relative;
            flex: 0 1 100%;
            transform: translateY(0.4rem);

            @include grid--fix-ie-flex-basis() {
                width: 100%;
            }
        }

        .location-intro--icon {
            flex: 0 0 auto;
            align-self: flex-start;
            margin-left: 16px;
            color: $c--brand-light-blue;
        }

        .location-intro--title-image {
            display: block;
            margin-bottom: 36px;
        }
    }

    // Facets

    // States
}

