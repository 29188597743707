.modal {
    // Vars

    // Support

    // Module
    & {
        position: relative;

        .modal--close-wrap {
            position: absolute;
            top: 0;
        }

        .modal--close {
            z-index: 3;
            position: fixed;
        }
    }

    // Facets
    & {
        // Full width modal overlay
        &.modal__base {
            width: 100%;

            .modal--close-wrap {
                right: 0;
            }

            .modal--close {
                transform: translateX(-100%);
            }

            .modal--close-button {
                margin: 20px;
            }
        }
    }

    // States
}
