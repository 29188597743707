.trs-location-nav-toggle {
    // Vars

    // Support

    // Module

    // Facets

    // States
    &-leave-active {
        transition: transition--props($trs--location-nav, opacity, transform);
    }

    &-enter-active {
        transition: transition--props($trs--location-nav $trs--location-nav-delay, opacity, transform);
    }

    &-enter, &-leave-to {
        opacity: 0;
        transform: translateY(100%);
    }
}
