// stylelint-disable max-nesting-depth
& {
    // Base
    & {
        .form-field--input {
            @include form-field--bool-target-both() {
                .form-field--box::before {
                    opacity: 0;
                }
            }
        }
    }

    // Hover
    & {
        .form-field--input {
            @include form-field--bool-is-checked(false) {
                @include form-field--bool-is-disabled(false) {
                    @include form-field--bool-has-hover() {
                        .form-field--box-icon {
                            opacity: 0.25;
                        }
                    }
                }
            }

            @include form-field--bool-is-checked(false) {
                @include form-field--bool-is-disabled(true) {
                    @include form-field--bool-has-hover() {
                        .form-field--box-icon {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }

    // Focus
    & {
        .form-field--input {
            @include form-field--bool-is-disabled(false) {
                @include form-field--bool-has-focus() {
                    .form-field--box::before {
                        opacity: 1;
                    }
                }
            }
        }
    }

    // Checked
    & {
        .form-field--input {
            @include form-field--bool-is-checked(true) {
                .form-field--box-icon {
                    opacity: 1;
                }
            }
        }
    }

    // Disabled
    & {
        .form-field--input {
            @include form-field--bool-is-disabled(true) {
                cursor: default;

                .form-field--label {
                    cursor: auto;
                }
            }
        }
    }

    // Is applied, when form-field is required
    &.form-field__is-required {
        .form-field--title-label {
            @include form-field--is-required();
        }
    }
}
