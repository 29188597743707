.carousel-bullet-nav {
    // Vars
    $sz--bullet: 6px;

    // Support

    // Module
    & {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        .carousel-bullet-nav--bullets {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            height: $sz--media-portal-inset;

            @include mq(('print', '<=sm')) {
                height: 46px;
            }
        }

        .carousel-bullet-nav--bullet-link {
            display: block;
            padding: 3px;
        }

        .carousel-bullet-nav--bullet-icon {
            @include size($sz--bullet);
            position: relative;
            display: block;
            border-radius: 50%;
            border: 1px solid $c--brand-light-blue;

            transition: $trs--common-props;

            &::before {
                @include overlay();
                content: '';
                pointer-events: none;
                box-shadow: 0 0 6px 1px rgba(color-shade($c--brand-light-blue, 20%), 0.8);
                opacity: 0;
                border-radius: 50%;
                overflow: hidden;

                transition: opacity $trs--base;
            }
        }
    }

    // Facets

    // States
    & {
        // Focus states
        // stylelint-disable max-nesting-depth
        & {
            .carousel-bullet-nav--bullet-link {
                @include has-focus(true) {
                    .carousel-bullet-nav--bullet-icon {
                        border-color: color-shade($c--brand-light-blue, 20%);

                        &::before {
                            opacity: 1;
                        }
                    }
                }

                &.carousel-bullet-nav--bullet-link__is-active {
                    @include has-focus(default) {
                        cursor: default;

                        .carousel-bullet-nav--bullet-icon {
                            border-color: $c--brand-light-blue;
                            background-color: $c--brand-light-blue;

                            &::before {
                                opacity: 0;
                            }
                        }
                    }
                }
            }
        }
        // stylelint-enable max-nesting-depth
    }
}
