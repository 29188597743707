.gallery-item {
    // Vars
    $sz--glyph-base: 26px;
    $sz--glyph-large: 40px;
    $sz--icon-base: 16px;
    $sz--icon-large: 24px;

    // Support

    // Module
    & {
        position: relative;
        overflow: hidden;

        @include mq(('screen' '<=sm')) {
            width: $sz--gallery-item-height-as-slider * $sz--gallery-item-ratio;
            height: $sz--gallery-item-height-as-slider;
        }

        @include mq(('print')) {
            width: $sz--gallery-item-height-in-print * $sz--gallery-item-ratio;
            height: $sz--gallery-item-height-in-print;
        }

        .gallery-item--type-indicator {
            @include overlay();
            pointer-events: none;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
        }

        .gallery-item--glyph {
            @include size($sz--glyph-base);
            z-index: 1;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;

            color: $c--brand-light-blue;
            background-color: $c--monochrome-white;
            border: $sz--border-width solid $c--brand-light-blue;
            border-radius: 50%;
        }

        .gallery-item--icon {
            @include size($sz--icon-base);
        }
    }

    // Facets
    & {
        // First item in
        &.gallery-item__is-first {
            @include mq(('screen' '>sm')) {
                .gallery-item--glyph {
                    @include size($sz--glyph-large);
                    border-width: 4px;
                }

                .gallery-item--icon {
                    @include size($sz--icon-large);
                }
            }
        }

        //&.gallery-item__image {}

        //&.gallery-item__video {}

        &.gallery-item__audio {
            .gallery-item--button {
                @include overlay();

                &::before, &::after {
                    @include overlay();
                    content: '';
                    pointer-events: none;
                    display: block;
                    background-color: $c--monochrome-white;
                    transition: transition--props($trs--base, opacity, background-position-x);
                }

                &::before {
                    $c--dark-wave: color-shade($c--monochrome-light-grey, 10%);
                    background-image: url('#{wave--svg($c--dark-wave)}');
                    opacity: 1;
                }

                &::after {
                    background-image: url('#{wave--svg($c--brand-light-blue)}');
                    opacity: 0;
                }

                @include has-focus(true) {
                    &::before, &::after {
                        background-position-x: 13px;
                    }

                    &::after {
                        opacity: 1;
                    }
                }
            }

            @include mq(('screen' '>sm')) {
                width: 100%;
                padding-top: 100% / 16 * 9;
            }
        }
    }

    // States
}
