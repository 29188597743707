.main-placeholder {
    // Vars

    // Support

    // Module
    & {
        .main-placeholder--col {
            @include grid--scaffold((ratio: 1));
        }
    }

    // Facets

    // States
}
