.map-nav {
    // Vars
    $sz--map-nav-width-split: 368px;
    $sz--map-nav-offset-split: 62px;
    $sz--padding-x: 16px;
    $sz--map-nav-content-max-height: (2 * $sz--map-nav-offset-split) + $sz--map-nav-dropdown-height;

    // Support

    // Module
    & {
        pointer-events: none;

        .map-nav--wrap {
            z-index: 1;
            position: relative;
        }

        .map-nav--backdrop {
            @include overlay();
            pointer-events: auto;
            cursor: default;
        }

        .map-nav--toggle {
            @include ff--deco-bold;
            pointer-events: auto;
            z-index: 1;
            left: 0;
            width: 100%;
            padding: 0 $sz--padding-x;
            background-color: $c--monochrome-white;
            border-right: 0 solid transparent;
            cursor: pointer;

            @include has-focus(true) {
                .map-nav--toggle-icon {
                    color: color-shade($c--brand-light-blue, 20%);
                }
            }
        }

        .map-nav--toggle-wrap {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
            justify-content: space-between;
        }

        .map-nav--toggle-text {
            position: relative;
            transform: translateY(2px); // NOTE: Cosmetic adaption
        }

        .map-nav--toggle-icon {
            color: $c--brand-light-blue;
            transition: transition--props($trs--base, transform, color);
        }

        .map-nav--accordion {
            padding-top: $sz--map-nav-dropdown-height;
            background-color: $c--monochrome-white;
            box-shadow: 2px 2px 0 0 rgba($c--monochrome-black, 0.16);
        }

        .map-nav--content {
            border-top: $sz--map-nav-sorter-height solid transparent;
        }

        .map-nav--sorter {
            z-index: 1;
            left: 0;
            width: 100%;
            height: $sz--map-nav-sorter-height;
            padding: 0 $sz--padding-x;
            border-top: $sz--border-width solid $c--monochrome-light-grey;
            border-bottom: $sz--border-width solid $c--monochrome-light-grey;
            border-right: 0 solid transparent;
            background-color: $c--monochrome-white;
            cursor: pointer;
        }

        .map-nav--sorter-wrap {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
        }

        .map-nav--sorter-title {
            position: relative;
            color: $c--monochrome-silver;
            font-size: $fz--x-small;
            line-height: $lh--x-small;
            transform: translateY(2px); // NOTE: Cosmetic adaption
        }

        .map-nav--sorter-indicator {
            @include ff--deco-medium;
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            align-items: center;
            width: 54px;
            height: 36px;
            font-size: $fz--medium;
            line-height: $lh--medium;
            border: solid $sz--border-width transparent;
            border-radius: 2px;

            transition: $trs--common-props;
        }

        .map-nav--sorter-indicator-text {
            position: relative;
            transform: translateY(2px); // NOTE: Cosmetic adaption
        }

        .map-nav--locations {
            padding: 10px 0;
        }

        .map-nav--location-link {
            @include ff--deco-medium;
            display: block;
            width: 100%;
            padding: 9px $sz--padding-x;
            color: $c--monochrome-steel-grey;

            transition: $trs--common-props;

            &.u-router-link-exact-active {
                color: $c--brand-light-blue;
                cursor: default;
            }

            &:not(.u-router-link-exact-active) {
                @include has-focus(true) {
                    background-color: rgba($c--monochrome-black, 0.1);
                }
            }
        }

        .map-nav--location-link-text {
            position: relative;
            display: block;
            transform: translateY(2px); // NOTE: Cosmetic adaption
        }
    }

    // Facets
    & {
        &.map-nav__mobile {
            border-top: $sz--header-height-base solid $c--monochrome-white;

            .map-nav--toggle {
                position: fixed;
                top: $sz--header-height-base - $sz--wave-height;
                height: $sz--map-nav-dropdown-height + $sz--wave-height;
                padding-top: $sz--wave-height;
            }

            .map-nav--sorter {
                position: fixed;
                top: $sz--header-height-base + $sz--map-nav-dropdown-height;
            }
        }

        &.map-nav__split {
            @include overlay();
            padding: $sz--map-nav-offset-split 0;

            .map-nav--wrap {
                position: relative;
                width: $sz--map-nav-width-split;
                height: 100%;
                margin: 0 auto;
            }

            .map-nav--toggle {
                position: absolute;
                height: $sz--map-nav-dropdown-height;
            }

            .map-nav--sorter {
                position: absolute;
                top: $sz--map-nav-dropdown-height;
            }

            .map-nav--content {
                @include ios-native-scrolling();
                height: calc(100vh - #{ $sz--map-nav-content-max-height });
                max-height: 492px;
            }
        }
    }

    // States
    & {
        // is-active
        & {
            &.map-nav__is-active {
                pointer-events: auto;

                .map-nav--toggle-icon {
                    transform: rotateX(-0.5turn);
                }
            }
        }

        // sorter (unsorted)
        &.map-nav__is-unsorted {
            .map-nav--sorter {
                @include has-focus(false) {
                    .map-nav--sorter-indicator {
                        color: $c--monochrome-silver;
                        border-color: $c--monochrome-silver;
                    }
                }

                @include has-focus(true) {
                    .map-nav--sorter-indicator {
                        background-color: rgba($c--monochrome-black, 0.1);
                    }
                }
            }
        }

        // sorter (sorted)
        &.map-nav__is-sorted {
            .map-nav--sorter {
                @include has-focus(false) {
                    .map-nav--sorter-indicator {
                        color: $c--monochrome-white;
                        background-color: $c--brand-light-blue;
                    }
                }

                @include has-focus(true) {
                    .map-nav--sorter-indicator {
                        background-color: color-shade($c--brand-light-blue, 20%);
                    }
                }
            }
        }
    }
}
