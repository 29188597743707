&.form-field__select {
    // Vars
    $sz--padding-top-select: 19px;
    $sz--padding-bottom-select: 15px;

    // Facets
    & {
        & {
            .form-field--input {
                @include ff--base-medium;
                padding-top: $sz--padding-top-select;
                padding-bottom: $sz--padding-bottom-select;
                cursor: pointer;
            }

            .form-field--action {
                pointer-events: none;
            }
        }

        &.form-field__is-empty {
            & {
                .form-field--input {
                    @include ff--base-medium;
                }
            }

            &.form-field__is-disabled {
                .form-field--input {
                    color: transparent;
                }
            }
        }
    }
}
