.trs-page-home {
    // Vars

    // Support

    // Module

    // Facets

    // States
    &-enter-active, &-leave-active {
        transition: transition--props($trs--base, opacity);
    }

    @include mq(('print', '<=lg')) {
        &-enter-active {
            .trs-page-home--map-nav {
                transition: transition--props($trs--mobile-menu, opacity, transform);
            }
        }

        &-leave-active {
            .trs-page-home--map-nav {
                transition: transition--props($trs--mobile-menu, opacity, transform);
            }
        }
    }

    &-enter, &-leave-to {
        opacity: 0;

        @include mq(('print', '<=lg')) {
            .trs-page-home--map-nav {
                opacity: 0;
                transform: translateY(($sz--map-nav-dropdown-height + $sz--header-height-base) * -1);
            }
        }
    }
}
