& {
    // Vars
    $c--text-floating-label-base: $c--monochrome-dark-grey;
    $c--text-floating-label-floating: $c--monochrome-steel-grey;

    // Support

    // Module
    & {
        .form-field--floating-label {
            color: $c--text-floating-label-base;
        }
    }

    // Facets
    & {
        // Is applied, when floating label is floating.
        &.form-field__is-floating {
            .form-field--floating-label {
                color: $c--text-floating-label-floating;
            }
        }

        // Is applied, when form-field is disabled.
        &.form-field__is-disabled {
            .form-field--floating-label {
                color: form-field--apply-style('disabled', 'color');
            }
        }

        // Is applied, when pseudo-class `:focus` is applied to `.form-field--input`.
        &.form-field__has-focus {
            .form-field--floating-label {
                //color: $c--text-floating-label-base;
            }
        }

        // Is applied, when form-field has an error state.
        &.form-field__has-error {
            .form-field--floating-label {
                color: $c--signal-error;
            }
        }

        // Is applied, when form-field is required
        &.form-field__is-required {
            .form-field--floating-label {
                @include form-field--is-required();
            }
        }
    }

    // States
}
