&.grid__placeholder {
    // Vars

    // Support

    // Module
    & {
        @each $key, $value in $grid--placeholder-spacing {
            @include mq($key) {
                @include grid--gutter($value);

                .grid--col.grid--col__page-title ~ .grid--col {
                    padding-top: 0;
                }
            }
        }
    }

    // Facets

    // States
}
