.carousel-slider {
    // Vars
    @if not variable-exists(trs--carousel) {
        $trs--carousel-speed: 300ms !global;
        $trs--carousel-fn: ease !global;
        $trs--carousel: $trs--carousel-speed $trs--carousel-fn !global;
    }

    // Support

    // Module
    & {
        position: relative;
        //width: 100%;
        //height: 100%;

        .carousel-slider--slides {
            //width: 100%;
            //height: 100%;
        }

        .carousel-slider--rail {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            //height: 100%;
            transition: transform $trs--carousel;
        }

        .carousel-slider--item {
            //display: flex;
            //flex-flow: row nowrap;
            //align-items: stretch;
            //flex: 0 0 auto;
            //overflow: hidden;
            min-height: 100vh;
            overflow: hidden;
        }
    }

    // Facets

    // States
}
