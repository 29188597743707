.overlay {
    // Vars

    // Support

    // Module
    & {
        @include is-selectable(false);

        .overlay--root {
            z-index: z-index('overlay');
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }

        .overlay--backdrop {
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background-color: rgba($c--monochrome-black, 0.5);
        }

        .overlay--display {
            // NOTE: remove for Modal + body scrolling
            @include ios-native-scrolling;
            position: relative;
            width: 100%;
            height: 100%;
        }

        .overlay--backdrop-click-area {
            @include overlay();
            z-index: 1;
        }

        .overlay--wrap-outer {
            @include is-selectable(true);
            display: table;
            width: 100%;
            height: 100%;
            table-layout: fixed;
        }

        .overlay--wrap-inner {
            position: relative;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
        }

        .overlay--container {
            z-index: 2;
            position: relative;
            width: 100%;
        }

        .overlay--revealer {
            display: flex;
            flex-flow: row nowrap;
            justify-content: center;
            text-align: left;
        }
    }

    // Facets

    // States
}
