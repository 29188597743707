.trs-wave-loader-fade {
    // Vars

    // Support

    // Module

    // Facets

    // States
    &-leave-active {
        transition: transition--props($trs--wave-loader, opacity);
    }

    &-enter-active {
        transition: transition--props($trs--wave-loader $trs--wave-loader-delay, opacity);
    }

    &-enter, &-leave-to {
        opacity: 0;
    }
}
