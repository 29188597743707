// stylelint-disable max-nesting-depth
&.form-field__bool {
    // Vars
    $fz--label: 1.4rem / 1rem * 10px; // Convert to px
    $lh--label: 1.5;

    $sz--label-gutter: 8px;

    $sz--box-gutter: 4px;
    $sz--box: ($fz--label + (2 * $sz--box-gutter));
    $sz--box-border: 1px;
    $sz--box-border-radius: $sz--border-radius;

    $sz--checkbox-icon: 16px;
    $sz--radio-icon: 12px;

    $sz--list-gutter-y: 8px;
    $sz--list-gutter-x: 20px;

    // Support
    @mixin form-field--bool-target-both() {
        &,
        & ~ .form-field--label-wrap {
            @content;
        }
    }

    @mixin form-field--bool-has-hover() {
        @include has-support(touchevents false) {
            @include form-field--bool-target-both() {
                &[data-hover],
                &:hover {
                    @include form-field--bool-target-both() {
                        @content;
                    }
                }
            }
        }
    }

    @mixin form-field--bool-has-focus() {
        @include form-field--bool-target-both() {
            &[data-focus],
            &:focus,
            &:active {
                @include form-field--bool-target-both() {
                    @content;
                }
            }
        }
    }

    @mixin form-field--bool-is-disabled($toggle) {
        @if ($toggle) {
            &:disabled {
                @include form-field--bool-target-both() {
                    @content;
                }
            }
        } @else {
            &:not(:disabled) {
                @include form-field--bool-target-both() {
                    @content;
                }
            }
        }
    }

    @mixin form-field--bool-is-checked($toggle) {
        @if ($toggle) {
            &[data-checked],
            &:checked {
                @include form-field--bool-target-both() {
                    @content;
                }
            }
        } @else {
            &:not(:checked):not([data-checked]) {
                @include form-field--bool-target-both() {
                    @content;
                }
            }
        }
    }

    // Module
    & {
        @include grid--root((cols: ('input-container')));
        @include grid--gutter((gutter: ($sz--list-gutter-y, $sz--list-gutter-x), cols: ('input-container')));
        font-size: $fz--label;
        line-height: 0;

        .form-field--input {
            &[type='checkbox'],
            &[type='radio'] {
                @include hide-visually;
            }
        }

        .form-field--input-container {
            display: flex;
            flex: 0 0 auto;
        }

        .form-field--title-label {
            cursor: default;
        }

        .form-field--label-wrap {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: baseline;
            cursor: pointer;

            max-height: $sz--box;

            transition: $trs--common-props;
        }

        .form-field--label {
            padding: 0 0 0 $sz--label-gutter;

            // Center label on the basis of the flex value `align-items: baseline`
            transform: translateY((($fz--label - $sz--box) / 2) - $sz--box-border - 0.5px);
        }

        .form-field--box {
            @include is-selectable(false);

            position: relative;
            display: block;
            flex: 0 0 auto;
            width: $sz--box;
            height: $sz--box;

            transition: $trs--common-props;

            &::before,
            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;

                transition: $trs--common-props;
            }

            &::before {
                z-index: 1;
            }

            &::after {
                z-index: 2;
                border-width: $sz--box-border;
                border-style: solid;
            }
        }

        .form-field--box-icon {
            pointer-events: none;
            z-index: 3;
            opacity: 0;
            transition: $trs--common-props;
        }
    }

    // types
    & {
        &.form-field__vertical {
            .form-field--row {
                flex-flow: column nowrap;
            }
        }

        &.form-field__multiline {
            .form-field--label-wrap {
                max-height: none;
            }

            .form-field--label {
                line-height: $lh--label;
            }
        }

        // implicit type checkbox
        & {
            .form-field--input[type='checkbox'] {
                ~ .form-field--label-wrap {
                    .form-field--box {
                        &,
                        &::after,
                        &::before {
                            border-radius: $sz--box-border-radius;
                        }
                    }

                    .form-field--box-icon {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        margin-left: ($sz--checkbox-icon / -2);
                        margin-top: ($sz--checkbox-icon / -2);
                        width: $sz--checkbox-icon;
                        height: $sz--checkbox-icon;
                    }
                }
            }
        }

        // implicit type radiobutton
        & {
            .form-field--input[type='radio'] {
                ~ .form-field--label-wrap {
                    .form-field--box {
                        &,
                        &::after,
                        &::before {
                            border-radius: $sz--box;
                        }
                    }

                    .form-field--box-icon {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: $sz--radio-icon;
                        height: $sz--radio-icon;
                        border-radius: $sz--box;
                        transform: translate(-50%, -50%);

                        background-color: currentColor;
                    }
                }
            }
        }
    }

    // Facets
    & {
        // Base facet
        @import 'facets/form-field__bool-facet-base';
    }

    // States
    @import 'form-field__bool-states-general';
}
