// stylelint-disable max-nesting-depth
& {
    // Vars
    $c--text-title-label: $c--label-text;
    $color-facets: (
        base: (
            'color': $c--brand-light-blue,
            'background-color': transparent,
            'border-color': $c--monochrome-silver,
        ),
        focus: (
            'color': null,
            'background-color': null,
            'border-color': $c--monochrome-dark-grey,
        ),
        disabled: (
            'color': $c--monochrome-silver,
            'background-color': $c--monochrome-light-grey,
            'border-color': $c--monochrome-silver,
        ),
    );

    // Module
    & {
        .form-field--title-label {
            color: $c--text-title-label;
        }
    }

    // States
    & {
        // Base
        .form-field--input {
            @include form-field--bool-target-both() {
                & {
                    .form-field--box {
                        background-color: form-field--apply-style('base', 'background-color');
                    }

                    .form-field--box::after {
                        border-color: form-field--apply-style('base', 'border-color');
                    }

                    .form-field--box-icon {
                        color: form-field--apply-style('base', 'border-color');
                    }
                }

                // Checkbox
                //&[type='checkbox'] ~ .form-field--label-wrap {}

                // Radio
                //&[type='radio'] ~ .form-field--label-wrap {}
            }
        }

        // Hover
        .form-field--input {
            @include form-field--bool-is-checked(false) {
                @include form-field--bool-is-disabled(false) {
                    @include form-field--bool-has-hover() {
                        // Radio / Checkbox
                        //& {}

                        // Checkbox
                        //&[type='checkbox'] {}

                        // Radio
                        //&[type='radio'] {}
                    }
                }
            }
        }

        // Focus
        .form-field--input {
            @include form-field--bool-is-disabled(false) {
                @include form-field--bool-has-focus() {
                    // Radio / Checkbox
                    & {
                        .form-field--box::after {
                            border-color: form-field--apply-style('focus', 'border-color');
                        }
                    }
                }
            }
        }

        // Checked
        .form-field--input {
            @include form-field--bool-is-checked(true) {
                // Radio / Checkbox
                & {
                    .form-field--box-icon {
                        color: form-field--apply-style('base', 'color');
                    }
                }

                // Radio (checked)
                //&[type='radio'] ~ .form-field--label-wrap {}

                // Checkbox (checked)
                //&[type='checkbox'] ~ .form-field--label-wrap {}
            }
        }

        // Error
        &.form-field__has-error .form-field--input {
            // Radio / Checkbox
            @include form-field--bool-target-both() {
                .form-field--box::after {
                    border-color: $c--signal-error;
                }
            }
        }

        // Disabled
        .form-field--input {
            @include form-field--bool-is-disabled(true) {
                // Radio / Checkbox
                & {
                    .form-field--box {
                        background-color: form-field--apply-style('disabled', 'background-color');
                    }

                    .form-field--box::after {
                        border-color: form-field--apply-style('disabled', 'border-color');
                    }

                    .form-field--box-icon {
                        color: form-field--apply-style('disabled', 'color');
                    }
                }

                // Radio (checked)
                //&[type='radio'] ~ .form-field--label-wrap {}

                // Checkbox (checked)
                //&[type='checkbox'] ~ .form-field--label-wrap {}
            }
        }
    }
}
