/// https://gist.github.com/jankorbel/2336513/cb2f68b59521438ac812ad72c1c93d8f656e49ad
///
.wave-loader {
    // Vars
    $c--wave-loader-light: $c--brand-light-blue;
    $c--wave-loader-dark: color-shade($c--monochrome-light-grey, 10%);

    $sz--wave-length: 80px;
    $sz--wave-height: 4px;

    $trs--wave-loader-fn: linear;
    $trs--wave-loader-speed: 7000ms;
    $trs--wave-loader-delay: 1000ms;
    $trs--wave-loader: $trs--wave-loader-speed $trs--wave-loader-fn;

    // Support
    @function wave-loader--stretch-ani($time) {
        $time--ani: $trs--wave-loader-speed;
        $time--delay: $trs--wave-loader-delay;
        @return $time * $time--ani / ($time--ani + $time--delay);
    }

    @keyframes ani-wave-loader {
        0% {
            width: 0;
        }

        #{ wave-loader--stretch-ani(50%) } {
            width: 100%;
            transform: translate(0);
            background-position-x: 0;
        }

        #{ wave-loader--stretch-ani(100%) } {
            width: 100%;
            transform: translate(100%);
            background-position-x: -$sz--wave-length;
        }

        100% {
            width: 100%;
            transform: translate(100%);
            background-position-x: -$sz--wave-length;
        }
    }

    // Module
    & {
        pointer-events: none;
        z-index: z-index(loader);
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;

        .wave-loader--wave {
            position: relative;
            width: $sz--wave-length;
            height: $sz--wave-height;
            overflow: hidden;

            &::before, &::after {
                @include overlay();
                content: '';
                pointer-events: none;
                display: block;
                transform-origin: right center;
                transition: transition--props($trs--base, opacity, background-position-x, width);
            }

            &::before {
                background-image: url('#{wave--svg($c--wave-loader-dark)}');
            }

            &::after {
                background-image: url('#{wave--svg($c--wave-loader-light)}');
                animation: ani-wave-loader $trs--wave-loader infinite;
            }
        }
    }

    // Facets
    & {
        &.wave-loader__base {
            @include overlay();
        }

        &.wave-loader__content {
            @include overlay(fixed);
            border-top: $sz--header-height-base solid transparent;
        }
    }

    // States
}
