/* stylelint-disable unit-blacklist, selector-max-id, declaration-no-important */

@media print {
    #__bs_notify__ {
        visibility: hidden;
    }

    .skel, .skel--main,
    .page-location, .page-location--content,
    .main-placeholder--container, .main-placeholder--row, .main-placeholder--col {
        position: static !important;
        display: block !important;
    }

    .location-nav, .main-nav, .header-panel, .map {
        display: none;
    }

    .split-view .split-view--content {
        width: 100%;
    }

    .page-location .page-location--content {
        padding: 0;
        margin: 0;
    }

    .mapbox-map .mapbox-map--actions-root {
        display: none;
    }

    .main-placeholder {
        .main-placeholder--container.main-placeholder--container {
            max-width: none;
            padding: 0;
        }

        .main-placeholder--col,
        p, ul, ol {
            page-break-inside: avoid;
        }
    }

    .gallery-item {
        &.gallery-item__video,
        &.gallery-item__audio {
            display: none !important;
        }
    }

    .tinted-image {
        .tinted-image--button::after,
        .tinted-image--thumb,
        .tinted-image--img.tinted-image--img__bw,
        .tinted-image--img.tinted-image--img__color {
            display: none;
        }

        .tinted-image--img.tinted-image--img__fallback {
            display: block;
        }
    }

    .link-list {
        .link-list--link {
            &::after {
                content: ' (' attr(href) ')';
                display: block;
            }
        }
    }
}
