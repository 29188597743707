%btn {
    cursor: pointer;
    transition: transition--props($trs--base, color, border-color, background-color, opacity, transform);
}

@mixin btn--shadowed($sz--shadow, $c--shadow) {
    position: relative;
    box-shadow: $sz--shadow $sz--shadow 0 0 $c--shadow;
    margin: $sz--shadow 0 0 $sz--shadow;
    transform: translate(-$sz--shadow, -$sz--shadow);
    backface-visibility: hidden;

    &:not(.u-reset) {
        &:not([disabled]):not([data-disabled]) {
            transition: transition--props($trs--btn, box-shadow, color, border-color, background-color, opacity, transform);
        }
    }

    &:active,
    &[data-active] {
        &:not([disabled]):not([data-disabled]) {
            transform: translate(0);
            box-shadow: 0 0 0 0 rgba($c--shadow, 0);
        }
    }

    &:disabled,
    &[data-disabled] {
        transform: translate(0);
        box-shadow: none;
    }
}

@mixin btn--primary() {
    $c--shadow: $c--brand-dark-blue;

    @include btn--shadowed(3px, $c--shadow);
    text-transform: uppercase;

    &:not([data-inert]) {
        &:not([disabled]):not([data-disabled]) {
            @include has-focus(true) {
                background-color: color-shade($c--brand-light-blue, 20%);
            }
        }
    }
}

@mixin btn--secondary() {
    position: relative;
    text-transform: uppercase;

    &:active,
    &[data-active] {
        &:not([disabled]):not([data-disabled]) {
            @include has-focus(false) {
                background-color: rgba($c--brand-light-blue, 0.2) !important; // stylelint-disable-line declaration-no-important
            }
        }
    }
}

@mixin btn--light() {
    @include btn--shadowed(2px, rgba($c--monochrome-black, 0.15));
}

@mixin btn--overlay() {
    position: relative;
    display: block;
    padding: 16px;
    border: none;

    .btn--bubble-icon {
        position: relative;
        transition: transform $trs--base;
    }

    &:not([data-inert]) {
        &:not([disabled]):not([data-disabled]) {
            @include has-focus(true) {
                .btn--bubble-icon {
                    transform: scale(1.5, 1.5);
                }
            }
        }
    }
}
