/// Controls the selection style of the current element and all its children
/// @param {boolean} $toggle - State toggle
///
@mixin is-selectable($toggle) {
    @if $toggle {
        & {
            -webkit-touch-callout: text;
            user-select: text;

            &::selection, ::selection {
                background-color: $c--label-selection;
            }
        }
    } @else {
        & {
            -webkit-touch-callout: none;
            user-select: none;

            &::selection, ::selection {
                background-color: transparent;
            }
        }
    }
}
