// Sizes
$sz--header-height-base: 76px;
$sz--header-height-split: 144px;
$sz--wave-height: 4px;

/// Border radius
$sz--border-radius: 0;
$sz--border-width: 2px;

/// map-nav
$sz--map-nav-dropdown-height: 50px;
$sz--map-nav-sorter-height: 58px;

/// gallery-item
$sz--gallery-item-ratio: (16 / 9);
$sz--gallery-item-height-as-slider: 86px;
$sz--gallery-item-height-in-print: 36mm; // stylelint-disable-line unit-blacklist

/// media-portal
$sz--media-portal-inset: 16px + (2 * 16px) + (2 * 20px);
$sz--media-portal-inset-small: (16px 16px 46px);

/// split-view
/* stylelint-disable length-zero-no-unit */
$split-spacing: (
    '>lg': (100vh 0px),
    lg: (null 200px),
    md: (null 280px),
    '<=sm': (null 200px),
);
$sz--location-nav-size: 80px;
/* stylelint-enable length-zero-no-unit */
