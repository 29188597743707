.header-panel {
    // Vars

    // Support

    // Module
    & {
        z-index: 3;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;

        .header-panel--main {
            @include wave--cap();
            z-index: 2;
            position: absolute;

            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            width: 100%;
            height: $sz--header-height-base;
            padding: 0 24px;

            @include mq(('print', '<=lg')) {
                padding: 0;
            }
        }

        .header-panel--detail {
            z-index: 3;
            position: absolute;
            padding: 16px;
        }

        .header-panel--brand {
            flex: 0 1 100%;
            height: 100%;
            padding: 20px 0;

            @include grid--fix-ie-flex-basis() {
                width: 100%;
            }

            @include mq(('print', '<=lg')) {
                padding: 20px 16px;
            }
        }

        .header-panel--home-link {
            display: inline-block;
            height: 100%;
        }

        .header-panel--logo {
            display: block;
            height: 100%;
        }

        .header-panel--rail {
            @include overlay(absolute, bottom, right);
            pointer-events: none;

            background-repeat: repeat-x;
            background-position-y: bottom;

            background-position-x: right;
            background-image: url('#{wave--svg($c--monochrome-light-grey)}');
        }

        .header-panel--mobile-nav {
            z-index: 1;
        }
    }

    // Facets

    // States
}
