.mobile-nav-toggle {
    // Vars

    // Support

    // Module
    & {
        .mobile-nav-toggle--btn {
            padding: 4px;
            color: $c--brand-light-blue;

            @include has-focus(true) {
                color: color-shade($c--brand-light-blue, 20%);
            }
        }

        .mobile-nav-toggle--wrap {
            display: flex;
            flex-flow: row nowrap;
            align-items: center;
        }

        .mobile-nav-toggle--text {
            @include ff--deco-bold;
            position: relative;
            transform: translateY(1px);
            font-size: $fz--small;
            line-height: $lh--small;
            text-transform: uppercase;
        }

        .mobile-nav-toggle--icon {
            transition: transform $trs--base;
        }
    }

    // Facets

    // States
    & {
        &.mobile-nav-toggle__is-open {
            .mobile-nav-toggle--icon {
                transform: rotateZ(-0.25turn);
            }
        }
    }
}
