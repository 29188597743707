.media-portal {
    // Vars

    // Support

    // Module
    & {
        //display: flex;
        //flex-flow: row nowrap;
        //justify-content: center;
        //align-items: stretch;
        //width: 100%;
        background-color: $c--monochrome-white;

        .media-portal--inlay {
            //position: relative;
            //display: flex;
            //flex-flow: row nowrap;
            //flex: 1 0 100%;
            //
            //@include grid--fix-ie-flex-basis() {
            //    width: 100%;
            //}
        }

        .media-portal--content {
            //@include overlay();
        }

        .media-portal--slider {
            //width: 100%;
        }
    }

    // Facets

    // States
}
