.location-partners {
    // Vars

    // Support

    // Module
    & {
        .location-partners--title {
            @include type--aside;
            display: block;
            margin-bottom: 12px;
        }

        .location-partners--wrap {
            overflow: hidden;
        }

        .location-partners--items {
            display: flex;
            flex-flow: row wrap;
            margin: -12px;
        }

        .location-partners--item {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 12px;
        }

        .location-partners--logo {
            display: block;
        }
    }

    // Facets

    // States
}

