.trs-home-intro-toggle {
    // Vars

    // Support

    // Module

    // Facets

    // States
    &-enter-active, &-leave-active {
        transition: transition--props($trs--mobile-menu, opacity, transform);
    }

    &-enter, &-leave-to {
        opacity: 0;
        transform: translateY(-25%);
    }
}
