/// Monochrome special decoration colors
///
$monochromes: (
    'white': #ffffff,
    'black': #000000,
    'light-grey': #e7e8e7,
    'silver': #a7a8aa,
    'dark-grey': #717479,
    'steel-grey': #272d34,
);

/// Brand specific colors
///
$brands: (
    'dark-blue': #252450,
    'light-blue': #76cbed,
    'light-mint': #aadbc4,
);

/// Single purpose colors
///
$labels: (
    'text': map-get($monochromes, 'steel-grey'),
    'link': map-get($brands, 'light-blue'),
    'selection': rgba(0, 0, 0, 0.15),
);

/// Signal colors
///
$signals: (
    'info': map-get($brands, 'light-blue'),
    'error': #e2113a,
    'warning': #eeac2b,
    'success': #1db57e,
);

/// Main color map
///
$color-maps: (
    monochrome: $monochromes,
    brand: $brands,
    label: $labels,
    signal: $signals,
);
