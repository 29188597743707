/// Global type definitions
///
$fz--large: 2.0rem;
$fz--medium: 1.8rem;
$fz--small: 1.6rem;
$fz--x-small: 1.4rem;
$fz--xx-small: 1.2rem;

$lh--large: 1.3;
$lh--medium: 1.56;
$lh--small: 1.75;
$lh--x-small: 1.3;
$lh--xx-small: 1.3;

/// Headline definitions
///
@mixin type--h1 {
    @include ff--deco-bold;
    font-size: 4.0rem;
    line-height: 1.4;

    @include mq(('print', '<=sm')) {
        font-size: 3.0rem;
        line-height: 1.4;
    }
}

@mixin type--h2 {
    @include ff--base-black;
    font-size: 2.6rem;

    @include mq(('print', '<=sm')) {
        font-size: 2.4rem;
        line-height: 1.25;
    }
}

@mixin type--h3 {
    @include ff--base-black;
    font-size: 1.8rem;
    line-height: 1.56;
}

@mixin type--h4 {
    @include ff--base-black;
}

@mixin type--h5 {
    @include ff--base-black;
}

@mixin type--h6 {
    @include ff--base-black;
}

@mixin type--copy {
    font-size: $fz--medium;
    line-height: $lh--medium;
    letter-spacing: 0.03rem;
}

@mixin type--aside {
    font-size: $fz--small;
    line-height: $lh--small;
    color: $c--monochrome-silver;
}

/// Basic typographic styling
///
html {
    font-size: 62.5%;
}

body {
    @include ff--base-medium;
    @include type--copy;

    color: $c--label-text;
}

/// Headlines
///
.skel .skel--main {
    h1:not(.u-reset),
    .u-reset__h1 {
        @include type--h1;
    }

    h2:not(.u-reset),
    .u-reset__h2 {
        @include type--h2;
    }

    h3:not(.u-reset),
    .u-reset__h3 {
        @include type--h3;
    }

    h4:not(.u-reset),
    .u-reset__h4 {
        @include type--h4;
    }

    h5:not(.u-reset),
    .u-reset__h5 {
        @include type--h5;
    }

    h6:not(.u-reset),
    .u-reset__h6 {
        @include type--h6;
    }

    /// Tags with visual implication
    ///
    b, strong {
        @include ff--base-black;
    }

    i, em {
        font-style: italic;
    }

    u {
        text-decoration: underline;
    }

    sub {
        font-size: $fz--xx-small;
        vertical-align: sub;
    }

    sup {
        font-size: $fz--xx-small;
        vertical-align: super;
    }

    /* stylelint-disable selector-no-qualifying-type */
    p, span {
        &.u-reset.u-reset__aside {
            @include type--aside;
        }
    }
    /* stylelint-enable selector-no-qualifying-type */

    /// Simple link
    ///
    a, .u-link {
        $c--link-base: $c--label-link;
        $c--link-hover: color-shade($c--label-link, 20%);

        color: currentColor;
        text-decoration: none;

        &:not(.u-reset):not(.btn) {
            @include has-focus(false) {
                color: $c--link-base;

                padding-bottom: 2px;
                background-image: url('#{wave--svg($c--link-base)}');
                background-repeat: repeat-x;
                background-position: bottom center;
            }

            @include has-focus(true) {
                color: color-shade($c--link-base, 20%);
            }
        }
    }

    /// Misc tags and definitions
    ///
    hr {
        display: block;
        border: 0;
        height: 1px;
        color: inherit; // NOTE: Firefox (v52) needs `color: inherit` to properly display `currentColor`
        background-color: currentColor;
    }

    /// Preformatted text (code)
    ///
    pre, code {
        font-family: monospace;
    }
}
