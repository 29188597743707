.split-view {
    // Vars

    // Support

    // Module
    & {
        pointer-events: none;
        z-index: z-index(content);
        position: relative;
        display: flex;
        flex: 1 0 auto;
        width: 100%;

        @include grid--fix-ie-flex-basis() {
            height: 100%;
        }

        .split-view--content {
            pointer-events: auto;
            position: relative;
            width: 50%;
            background-color: $c--monochrome-white;
            padding: $sz--header-height-split 0 ($sz--location-nav-size + $sz--placeholder-spacing-vertical-base);
        }

        .split-view--wrap {
            position: relative;
        }

        .split-view--header {
            z-index: 1;
            position: absolute;
            top: 0;
            left: 0;

            display: flex;
            flex-flow: row nowrap;
            width: 100%;
            padding-top: 36px;
        }

        .split-view--nav {
            flex: 1 0 100%;

            @include grid--fix-ie-flex-basis() {
                width: 100%;
            }
        }

        .split-view--loader {
            pointer-events: none;
        }
    }

    // Facets
    & {
        // Mobile
        & {
            @include mq(('<=lg')) {
                flex-flow: column nowrap;

                .split-view--content {
                    display: flex;
                    flex-flow: column nowrap;
                    flex: 1 0 auto;
                    width: 100%;
                    padding: $sz--placeholder-spacing-vertical-small 0 ($sz--placeholder-spacing-vertical-small + 8px);
                }

                .split-view--loader {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        @include mq(('screen' '<=lg')) {
            .split-view--content {
                &.split-view--content__desktop {
                    display: none;
                }
            }
        }

        // Desktop
        & {
            @include mq(('>lg')) {
                flex-flow: row nowrap;
                justify-content: flex-end;

                .split-view--loader {
                    position: fixed;
                    top: 0;
                    left: 50%;
                    height: 100%;
                    width: 50%;
                }
            }

            @include mq(('screen' '>lg')) {
                .split-view--content {
                    &.split-view--content__mobile {
                        display: none;
                    }
                }
            }
        }
    }

    // States
}
